import CloseIcon from "@mui/icons-material/Close";
import PlaceIcon from "@mui/icons-material/Place";
import {
    Avatar,
    Box,
    Button,
    Chip,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { conversation } from "../redux/conversationSlice";
import { messageThunk, setConversationsId } from "../redux/messageSlice";
import { setUserId, toggleModal } from "../redux/userImagesSlice";
import Utils from "../utils/Utils";
import PostDirectory from "./PostDirectory";
import MessagesIcon from "./icons/MessagesIcon";

const { serverURL, getUserFeedLink } = Utils;

function User({ username ,posts,pageType,showMessage}) {
  const { t, i18n } = useTranslation(["common"]);
  const [isProfileShown, setIsProfileShown] = useState(false);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("idle");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isProfileShown) return;
    (async () => {
      let userDetails = localStorage.getItem("user_details") || "{}";
      let userDetails_ = JSON.parse(userDetails);
      let senderId;
      if (Object.keys(userDetails_).length > 0) {
        senderId = userDetails_._id;
      }
      setStatus("loading");
      let receiverId = posts.authorID;
      const response = await fetch(`${serverURL}/find-user/${username}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              senderId,
              receiverId,
              language: i18n.language,
          }),
      });
      if (!response.ok) {
        toast.error(`${t("failedToLoad")} ${await response.text()}`);
        return setStatus("error");
      }
      setUser(await response.json());
      return setStatus("success");
    })();
  }, [username, isProfileShown, t, i18n.language]);

  const handleAvatarClick = async () => {
    await dispatch(setUserId(posts.authorID));
    dispatch(toggleModal());
  };

  const createNewConversations = async (userData) => {
    const response = await fetch(`${serverURL}/chat/conversations/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  };

  const showChatThread = async () => {
    let userDetails = localStorage.getItem("user_details") || "{}";
    let userDetails_ = JSON.parse(userDetails);

    let userId;
    if (Object.keys(userDetails_).length > 0) {
      userId = userDetails_._id;
    }
    if (userId !== posts.authorID) {
      let senderId;
      if (user?.conversations) {
        let conversationId_ = user?.conversations;
        let payLoad_ = { conversationId: conversationId_, userId: userId,skip:0};
        dispatch(messageThunk(payLoad_));
        dispatch(setConversationsId({ conversationId: conversationId_ }));
        const params = new URLSearchParams({ thread_id: conversationId_ });
        navigate(
          `/messages?${params.toString()}&chatType=new&chatSession=${
            posts.authorID
          }`
        );
        dispatch(conversation(userId));
      } else {
        if (Object.keys(userDetails_).length > 0) {
          senderId = userDetails_._id;
          let payLoad = { senderId, receiverId: posts.authorID };
          let response = await createNewConversations(payLoad);
          if (response.status === 200) {
            let newConversationId = response.conversation.conversationId;
            const params = new URLSearchParams({
              thread_id: newConversationId,
            });
            navigate(
              `/messages?${params.toString()}&chatType=new&chatSession=${
                posts.authorID
              }`
            );

            let payLoad_ = {
              conversationId: newConversationId,
              userId: userId,
              skip:0
            };
            dispatch(messageThunk(payLoad_));
            dispatch(setConversationsId({ conversationId: newConversationId }));

            if (Object.keys(userDetails_).length > 0) {
              let userId = userDetails_._id;
              dispatch(conversation(userId));
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Button
        sx={{
          p: 0,
          px: 1,
          fontWeight: "bold",
          "&:hover": {
            background: "transparent",
            textDecoration: "underline",
          },
        }}
        fontSize="medium"
        onClick={() => setIsProfileShown(true)}
      >
        {username}
      </Button>
      <Modal
        aria-labelledby="user-info"
        aria-describedby="User info"
        open={isProfileShown}
        onClose={() => setIsProfileShown(false)}
        closeAfterTransition
        disableScrollLock
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 250,
          },
        }}
        sx={{
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: 2,
          "& .MuiPaper-root": {
            position: "unset",
            zIndex: 1,
          },
        }}
      >
        <Fade in={isProfileShown}>
          <Paper
            elevation={2}
            sx={{
              display: "block",
              position: "absolute",
              top: 0,
              maxWidth: "600px",
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
              p: 3,
              minWidth: "280px",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "stretch",
                  }}
                >
                  {status !== "success" ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        m: 0.5,
                        borderRadius: 5,
                        overflow: "hidden",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  ) : (
                    <Button
                      onClick={handleAvatarClick}
                      sx={{
                        p: 0,
                        m: 0.5,
                        ml: 0,
                        borderRadius: 5,
                        minWidth: "unset",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: 5,
                          overflow: "hidden",
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <Avatar
                          sx={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={user?.profilePic?.url || null}
                          alt={user?.username}
                        />
                      </Box>
                    </Button>
                  )}
                  <Box
                    sx={{
                      flex: 1,
                      m: 0.5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {status !== "success" ? (
                      <>
                        <Skeleton variant="text" width="50%" />
                        <Skeleton variant="text" width="70%" />
                        <Skeleton variant="text" width="80%" />
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {user?.username}
                        </Typography>
                        <Typography>
                          <Typography
                            component="span"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {t(`${user?.gender?.toLowerCase() || ""}`)}
                          </Typography>
                          <Typography component="span" sx={{ mx: 1 }}>
                            &#183;
                          </Typography>
                          <Typography component="span">
                            {new Date().getFullYear() - user.yearOfBirth || ""}{" "}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: -0.5,
                            }}
                          >
                            <PlaceIcon />
                            {user?.city}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </Box>
                  <IconButton
                    onClick={() => setIsProfileShown(false)}
                    sx={{ ml: "auto", alignSelf: "flex-start" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              {status !== "success" ? (
                <>
                  <Skeleton variant="text" width="80%" height="2rem" />
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="60%" />
                </>
              ) : (
                <Box>
                    {user.labels &&
                          Object.keys(user.labels).map((category) => (
                              <Box key={category}>
                                  <Box className="flex flex-row items-center my-2">
                                      <Typography
                                          variant="h5"
                                          className="whitespace-nowrap"
                                      >
                                          {user.translatedLabels.categories[category]}
                                      </Typography>
                                      <hr className="ml-2 inline-block w-full border-gray-500/50" />
                                  </Box>
                                  <Box className="flex gap-1 flex-wrap">
                                      {user.labels[category].map((item) => (
                                          <Chip
                                              key={item}
                                              label={
                                                  user.translatedLabels.translations[category][
                                                      item
                                                  ]
                                              }
                                              variant="filled"
                                          />
                                      ))}
                                  </Box>
                              </Box>
                          ))}
                  {showMessage &&
                    <>
                      {pageType !== 'feeds' &&
                        <Box className="flex justify-between">
                          <Typography variant="h5">{t("subscriptions")}</Typography>
                          <Button onClick={()=>{showChatThread()}} variant="outlined" startIcon={<MessagesIcon />}>
                            {t("message")}
                          </Button>
                        </Box>
                      }
                   </>
                  }
                  {user?.subscribedInterests?.length === 0 && (
                    <Typography>{t("noSubscriptions")}</Typography>
                  )}
                  <List
                    sx={{
                      "& .MuiListItem-root": {
                        p: 0,
                        px: 1,
                      },
                    }}
                  >
                    {user?.subscribedInterests?.map((item) => (
                      <ListItem key={item._id} className="[&_a]:text-blue-600">
                        <NavLink
                          onClick={() => setIsProfileShown(false)}
                          to={getUserFeedLink(item.path, user._id)}
                        >
                          <PostDirectory
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            post={item}
                          />
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

export default User;
