import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";
import LocationAndRange from "../components/LocationAndRange";
import ProfileImages from "../components/profile/ProfileImages";
import { editProfile, setAlertMessage } from "../redux/authSlice";
import "../style/Profile.css";

const years = [];
for (let year = new Date().getFullYear(); year >= 1925; year -= 1) {
  years.push({
    value: year,
    label: year,
  });
}

function Profile() {
  const { t, i18n } = useTranslation(["profile-setting", "common"]);
  const { allLanguages: languages } = i18n;
  const { status, isLoggedIn, profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [interestRange, setInterestRange] = useState("");
  const [location, setLocation] = useState({});
  const [profilePic, setProfilePic] = useState({});
  const [selectedLabels, setSelectedLabels] = useState({});

  // keep the states sync
  const hasRunRef = useRef(false);
  useEffect(() => {
    if (hasRunRef.current) return;
    setUsername(profile.username);
    setGender(profile.gender || "male");
    setDefaultLanguage(
      profile.defaultLanguage || localStorage.getItem("i18nextLng")
    );
    setYearOfBirth(profile.yearOfBirth || years[0].value);
    setLocation(profile.defaultLocation || {});
    setInterestRange(profile.interestRange);
    setProfilePic(profile.profilePic);
    setSelectedLabels(profile?.labels || {})
    hasRunRef.current = true;
  }, [profile]);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const canSubmit = useRef(false);

  const handleSubmit = useCallback(
    async (e) => {
      if (!canSubmit.current) {
        canSubmit.current = !canSubmit.current;
        return null;
      }
      if (e) e.preventDefault();
      if (!isFormDirty) return null;
      if (!username)
        return dispatch(
          setAlertMessage({
            message: t("usernameRequired"),
            show: true,
            type: "error",
          })
        );
      // if (!email)
      //     return dispatch(
      //         setAlertMessage({ message: t('emailRequired'), show: true, type: 'error' })
      //     );
      if (!location)
        return dispatch(
          setAlertMessage({
            message: t("locationRequired"),
            show: true,
            type: "error",
          })
        );
      const updates = {
        id: profile._id,
        updates: {
          username,
          gender,
          yearOfBirth,
          profilePic,
          defaultLocation: location,
          defaultLanguage,
          interestRange,
          labels: selectedLabels
        },
      };
      return dispatch(editProfile(updates));
    },
    [
      defaultLanguage,
      profilePic,
      gender,
      interestRange,
      isFormDirty,
      location,
      profile._id,
      t,
      selectedLabels,
      username,
      yearOfBirth,
      dispatch,
    ]
  );
  const handleLanguageChange = async (lang) => {
    setIsFormDirty(true);
    setDefaultLanguage(lang);
    await handleSubmit(null, () => {});
    localStorage.setItem("i18nextLng", lang);
  };
  const handleChange = (e, setter) => {
    setter(e.target.value);
    setIsFormDirty(true);
  };

  // change the language after submission
  useEffect(() => {
    if (i18n.language === profile.defaultLanguage) return;
    i18n.changeLanguage(profile.defaultLanguage);
  }, [profile.defaultLanguage, i18n]);

  // delay the submission when the range value changes to limit api calls
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      // Skip the initial call when the component is mounted
      isMounted.current = true;
      return;
    }
    setIsFormDirty(true);

    const debounceTimeout = setTimeout(() => {
      handleSubmit();
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [location, interestRange, handleSubmit]);

  return (
    <Box className="w-full" alignSelf="center">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        {status === "success" && isLoggedIn ? (
          <>
            <Typography variant="h4" component="h1" textAlign="center" my={2}>
              {t("profileSettings")}
            </Typography>
            <ProfileImages profile={profile} t={t} selectedLabels={selectedLabels} setSelectedLabels={setSelectedLabels} dispatch={dispatch} updateLabels={handleSubmit} />
            <form onSubmit={handleSubmit}>
              <TextField
                id="username"
                label={t("common:username")}
                name="username"
                variant="outlined"
                sx={{ width: "100%", my: 1 }}
                value={username}
                InputLabelProps={{ shrink: !!username }}
                onChange={(e) => handleChange(e, setUsername)}
              />
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                <TextField
                  id="email"
                  label={t("common:email")}
                  name="email"
                  variant="outlined"
                  sx={{ width: "100%", my: 1 }}
                  value={profile?.email}
                  disabled
                  InputLabelProps={{ shrink: !!profile?.email }}
                />
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ my: 1, ml: 1 }}
                  to="/change-email"
                  component={NavLink}
                >
                  {t("common:edit")}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                <FormControl sx={{ width: "100%", my: 1, mr: 1 }}>
                  <InputLabel id="yob-label">
                    {t("common:yearOfBirth")}
                  </InputLabel>
                  <Select
                    labelId="yob-label"
                    id="yob"
                    value={yearOfBirth}
                    label={t("common:yearOfBirth")}
                    onChange={(e) => handleChange(e, setYearOfBirth)}
                  >
                    {years.map((year) => (
                      <MenuItem key={year.value} value={year.value}>
                        {year.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ width: "100%", my: 1, ml: 1 }}>
                  <InputLabel id="gender-label">
                    {t("common:gender")}
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    value={gender}
                    label={t("common:gender")}
                    onChange={(e) => handleChange(e, setGender)}
                  >
                    <MenuItem value="male" selected>
                      {t("common:male")}
                    </MenuItem>
                    <MenuItem value="female">{t("common:female")}</MenuItem>
                    <MenuItem value="other">{t("common:other")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <FormControl fullWidth sx={{ width: "100%", my: 1 }}>
                <InputLabel id="language-label">
                  {t("common:defaultLanguage")}
                </InputLabel>
                <Select
                  labelId="language-label"
                  id="defaultLanguage"
                  value={defaultLanguage}
                  label={t("common:defaultLanguage")}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                >
                  {languages.map((lan) => (
                    <MenuItem key={lan.code} value={lan.code}>
                      {lan.nativeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {
                location?.lat &&
                  <LocationAndRange
                  interestRange={interestRange}
                  setInterestRange={setInterestRange}
                  location={location}
                  setLocation={setLocation}
                  />
                }
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  color="success"
                  variant="contained"
                  sx={{ borderRadius: 10, mt: 2 }}
                >
                  {t("common:save")}
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <Loader />
        )}
      </Paper>
    </Box>
  );
}

export default Profile;
