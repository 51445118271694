import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { useSelector,useDispatch } from 'react-redux';
import {conversation,setConversations} from  '../../redux/conversationSlice'
import { useSearchParams } from 'react-router-dom';
import {messageThunk,setUser,setConversationsId,setMessages} from '../../redux/messageSlice'
import Utils from "../../utils/Utils";
const { serverURL } = Utils;


export default function SideBar({socket,scrollDownHandler}) {
    const data              = useSelector(state=>state.conversation.conversations)
    const conversationsId   = useSelector(state=>state.message.conversationsId)
    const messages_         = useSelector(state=>state.message.data)
    const liveUser          = useSelector(state=>state.liveUser.liveUsers)
    const useProfileData    = useSelector(state=>state.auth.profile)
    const dispatch          = useDispatch()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const thread_id = searchParams.get('thread_id')
    let chatType = searchParams.get('chatType'); 
    let chatSession = searchParams.get('chatSession');
    let [latestMessage_,setLatestMessage] = React.useState([])

    const createNewConversations = async (userData) => {
        const response = await fetch(`${serverURL}/chat/conversations/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(userData),
            credentials: "include",
        });
        if (!response.ok) throw new Error(await response.text());
        const data = await response.json();
        return data;
    }
    
    const fetchMessages = async(conversationId,user) => {
        if (thread_id === conversationId ) return true
        localStorage.setItem('limit',0) 
        scrollDownHandler()
        dispatch(setMessages([]))
        if (conversationId){
            let payLoad_ = {conversationId,userId:useProfileData._id,skip:0}
            dispatch(messageThunk(payLoad_))
            dispatch(setConversationsId(conversationId))
            const params = new URLSearchParams({ thread_id:conversationId});
            navigate(`/messages?${params.toString()}`);
        } else {
            let payLoad = {senderId:useProfileData._id,receiverId:user.id}
            let response = await createNewConversations(payLoad)
            const {status} = response
            if (status === 200){
                let newConversationId  = response.conversation.conversationId
                let payLoad_ = {conversationId:newConversationId,userId:useProfileData._id,skip:0}
                dispatch(messageThunk(payLoad_))
                dispatch(setConversationsId({conversationId:newConversationId}))
                const params = new URLSearchParams({ thread_id:newConversationId});
                navigate(`/messages?${params.toString()}`);
                let userDetails = localStorage.getItem('user_details') || "{}"
                let userDetails_ = JSON.parse(userDetails)
                if (Object.keys(userDetails_).length > 0 ){
                    let userId = userDetails_._id
                    dispatch(conversation(userId));
                }
            }
        }
        dispatch(setUser({user}))
    };

    function getMediaTypeFromUrl(url) {
        if (url){
            const searchString = 'https://ykop-uploads.s3.amazonaws.com';
            return url.includes(searchString);
        } else {
            return false
        }
    }
    
    React.useEffect(()=>{
        socket?.on("sendConverstionsUser", data => {
            dispatch(setConversations({conversations:data}))
        })

        socket?.on('lastestMessage',(data)=>{
            setLatestMessage(data)
        })
    },[socket])

    React.useEffect(()=>{
        if (chatType && chatType === 'new'){
            let receiverId = chatSession
            socket?.emit("loadUser", {receiverId});
        }
    },[chatType])

    
    return (
        <List sx={{paddingTop:"0px"}} component="nav" className='user_nav_list' aria-label="main mailbox folders">
            { data.map(({conversationId,user,latestMessage,unreadCount},index)=>{
    
                let profileUrl_ = "https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671142.jpg?size=626&ext=jpg"
                let latest_message = latestMessage
                let unreadCount_  = unreadCount
                let isLive =  false
                let liveUser_ =  liveUser.filter(userData=>{return userData.userId == user.id})
                if (liveUser_.length > 0){isLive = true}    
                let isActiveTab = ''
                

                if (thread_id != null &&  conversationId != null){
                    if (thread_id === conversationId){
                        isActiveTab = 'active user_list'
                    } else {
                        isActiveTab = ''
                    }
                }
                let filterMessages = latestMessage_.filter(userData=>{
                    return userData.conversationId === conversationId
                })

                if (filterMessages.length > 0){
                    latest_message = filterMessages[0].latestMessage
                }

                let messageType =  getMediaTypeFromUrl(latest_message);

                let filterMessagesCounter = latestMessage_.filter(userData=>{  ;
                    return userData.conversationId === conversationId
                })
                if (filterMessagesCounter.length > 0){
                    unreadCount_ = filterMessages[0].unreadCount
                }
                

                if (user?.profilePic?.profilePic){
                    profileUrl_ = user.profilePic.profilePic.url
                }
                
                return (
                    <Box key={index} className={`${isActiveTab} user_list`}>
                        <ListItemButton
                            onClick={() => fetchMessages(conversationId,user,profileUrl_)}
                        >   
                            <ListItemAvatar>
                                <Badge color="secondary" variant="dot" invisible={!isLive}>
                                    <Avatar alt={user.username} src={profileUrl_} />
                                </Badge>
                            </ListItemAvatar>
                            
                            <ListItemText
                                primary={user.username}
                                className='latestMsg'
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline',textTransform:"capitalize"}}
                                            component="span"
                                            variant="body2"
                                            id="latestMsg"
                                            color="text.primary"
                                        >
                                        
                                        { messageType?
                                            "New file"
                                            :
                                            latest_message
                                        } 
                                        </Typography>
                                        {unreadCount_ !== 0 &&
                                            <Box id="unReadCount">
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    id="msgCount"
                                                    color="text.primary"
                                                >
                                                {unreadCount_}
                                                </Typography>
                                            </Box>
                                        }
                                    </React.Fragment>
                                }
                            />
                        </ListItemButton>
                        <Divider/>
                    </Box>
                )
            })}
        </List>
    );
}
